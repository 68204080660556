import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  newCustomers(data) {
    // const p = paramsFactory(params);
    return api.post(`/api/v1/task/new-customers`, data);
  },
  maintenanceTasks(data) {
    // const p = paramsFactory(params);
    return api.post(`/api/v1/task/maintenance`, data);
  },
  subscriptionRequestTasks(data) {
    return api.post(`/api/v1/task/subscription-request`, data);
  },
  create(data) {
    return api.post(`/api/v1/task`, data);
  },
  showById(id) {
    return api.get(`/api/v1/task/${id}`);
  },
  status() {
    return api.get(`/api/v1/task/status`);
  },
  reply(data) {
    return api.post(`/api/v1/task-reply`, data);
  },
  assignResponsible(data) {
    return api.put(`/api/v1/task/assign-responsible/${data.task_id}`, data);
  },
  changeStatus(data) {
    return api.put(`/api/v1/task/change-status/${data.id}`, data);
  },
  update(data) {
    return api.put(`/api/v1/task/${data.id}`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/task/${id}`);
  },
  deleteReply(id) {
    return api.delete(`/api/v1/task-reply/${id}`);
  },
  solutions() {
    return api.get(`/api/v1/task/solutions`);
  },
  types() {
    return api.get(`/api/v1/task/type`);
  },
  addSolutions(data) {
    return api.put(`/api/v1/task/solutions/${data.task_id}`, data);
  },
  assignMultipleResponsible(data) {
    return api.put(`/api/v1/task/assign-multi-responsible`, data);
  },
  changeIsExternalStatus(data) {
    return api.put(`/api/v1/task/external/${data.id}`);
  },

  getTaskNotification() {
    return api.get(`/api/v1/task/statistics`);
  },
  changeSubscriptionRequests(id, data) {
    return api.put(`/api/v1/task/change-subscription/${id}`, data);
  },
  fastSubscribe(id) {
    return api.put(`/api/v1/task/collect-money/${id}`);
  },
  exportToExcel(data) {
    // const p = paramsFactory(params);
    return api.post(`/api/v1/task/new-customers`, data, {
      responseType: "blob",
    });
  },
  exportToExcelMaintenance(data) {
    // const p = paramsFactory(params);
    return api.post(`/api/v1/task/maintenance`, data, {
      responseType: "blob",
    });
  },
  assignTeamLeader(data) {
    return api.put(`/api/v1/task/assign-team-leader/${data.id}`, data);
  },
  getRecords(date) {
    return api.get(
      `/api/v1/task/get-records?start_date=${date.start_date}&end_date=${date.end_date}`,
      date
    );
  },
  addNewRecord(data) {
    return api.post(`/api/v1/task-record`, data);
  },
  getRecordFullPath(path) {
    return api.post(
      `/api/v1/task-record/get`,
      { rec_path: path },
      { responseType: "blob" }
    );
  },
};
